import moment from 'moment';
import { insurerConfig } from 'views/user/CreateInsurance/insurer.config';
import * as yup from 'yup';
export const vehicleColors = [
  "Alice Blue", "Antique White", "Aquamarine", "Azure", "Beige", "Bisque", "Black",
  "Blue", "Blue Violet", "Brown", "Burly Wood", "Cadet Blue", "Chocolate", "Coral",
  "Corn Silk", "Crimson", "Cyan", "Dark Green", "Dark Olive Green", "Dark Sea Green",
  "Dark Slate Blue", "Dark Slate Grey", "Dim Grey", "Fire Brick", "Floral White",
  "Forest Green", "Gold", "Green", "Green Yellow", "Grey", "Honey Dew", "Indian Red",
  "Ivory", "Khaki", "Lavender", "Lawn Green", "Light Cyan", "Light Grey", "Light Pink",
  "Light Salmon", "Lightblue", "Lime Green", "Magenta", "Maroon", "Medium Blue",
  "Midnight Blue", "Mint Cream", "Misty Rose", "Navy Blue", "Orange", "Orchid", "Pink",
  "Powder Blue", "Purple", "Red", "Royal Blue", "Royalblue5", "Salmon", "Sandy Brown",
  "Sea Green", "Sienna", "Silver", "Sky Blue", "Slate Blue", "Slate Grey", "Snow",
  "Spring Green", "Steel Blue"
];
export const dependencies = {
  dummyDropdown: {
    name:'Dummy Dropdown',
    type:'dropdown' as const,
    dropdownValues:['1-10','11-20','20-30'],
    validation:yup.string()
    .required(`Please select a dummy value`)
    .oneOf(['1-10','11-20','20-30'], `Please select a valid dummy value`),
    key:'dropdown' as const
  },
  companyTypeDropdown: {
    name:'Company Type',
    type:'dropdown',
    dropdownValues:[],
    validation:yup.string()
    .required(`Please select a company type.`),
    key:'companyType'
  } as const,
  vehicleColorDropdown: {
    name:'Vehicle Color',
    type:'dropdown' as const,
    dropdownValues:vehicleColors.map((color) => ({ label: color, value: color })),
    validation:yup.string()
    .optional(),
    key:'insurerSpecific.vehicleColor' as const
  },
  aadhaar: {
    name: 'Aadhaar Card',
    type: 'text' as const,
    primary: true,
    supporting: false,
    dependent: [],
    key:'aadhaar' as const
  },
  pan: {
    name: 'PAN Card',
    type: 'text' as const,
    primary: true,
    supporting: true,
    dependent: [],
    key:'pan' as const
  },
  kycGstin: {
    name: 'GSTIN',
    type: 'text' as const,
    primary: false,
    supporting: true,
    dependent: [],
    key:'kycGstin' as const,
    capitalize:true,
    validation:yup.string()
    .required('GSTIN is required')
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'Invalid GSTIN Number.')
  },
  form60: {
    name: 'Form 60',
    type: 'upload' as const,
    primary: false,
    supporting: true,
    frontAndBack:true,
    dependent: [],
    key:'form60' as const
  },
  motherName:{
    name:"Mother's Name",
    type: 'text' as const,
    primary: false,
    supporting: true,
    dependent: [],
    key:'motherName' as const,
    validation:yup.string().required(`Mother's name is required`)
  },
  fatherName:{
    name:"Father's Name",
    type: 'text' as const,
    primary: false,
    supporting: true,
    dependent: [],
    key:'fatherName' as const,
    validation:yup.string().required(`Father's name is required`)
  },
  voterId: {
    name: 'Voter ID',
    type: 'upload' as const,
    primary: true,
    supporting: false,
    // frontAndBack:true,
    key:'voterId' as const,
    validation:yup.string().required(`Voter ID is required`)
  },
  aadhaarEnrollmentNumber: {
    name: 'Aadhaar Enrollment Number',
    type: 'text',
    key: 'aadhaarEnrollmentNumber',
    validation: yup.string().required('Aadhaar Enrollment Number is required.')
  } as const,
  aadhaarEnrollmentDate: {
    name: 'Aadhaar Enrollment Date',
    type: 'date',
    key: 'aadhaarEnrollmentDate',
    validation: yup.string()
    .required('Aadhaar Enrollment Date is required.')
    .test('is-date', 'Aadhaar Enrollment Date must be a valid date', value => {
      return moment(value, 'DD-MM-YYYY', true).isValid();
    })
  } as const
};
export type Dependent = typeof dependencies.aadhaar;
export type Dependents = typeof dependencies;
export const dummyUnitedConfig: insurerConfig = {
  name: 'United India Insurance Company Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      },
    },
    N: {
      od1tp1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
      od1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
      tp1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
    },
  },
  previousInsurers: [
    'ACKO GENERAL INSURANCE LIMITED',
    'BAJAJ ALLIANZ GENERAL INSURANCE CO.LTD',
    'BHARTI AXA GENERAL INSURANCE COMPANY LIMITED',
    'CHOLAMANDALAM MS GENERAL INSURANCE CO.LTD.',
    'DHFL GENERAL INSURANCE LIMITED',
    'EDELWEISS GENERAL INSURANCE COMPANY',
    'FUTURE GENERALI INDIA INSURANCE COMPANY LIMITED',
    'GO DIGIT GENERAL INSURANCE LIMITED',
    'HDFC ERGO GENERAL INSURANCE CO.LTD.',
    'ICICI LOMBARD GENERAL INSURANCE CO. LTD.',
    'IFFCO TOKIO GENERAL INSURANCE CO. LTD.',
    'Kotak General Insurance',
    'L&T GENERAL INSURANCE COMPANY LIMITED',
    'LIBERTY VIDEOCON GENERAL INSURANCE COMPANY',
    'MAGMA HDI GENERAL INSURANCE CO.LTD',
    'NATIONAL INSURANCE CO.LTD.',
    'NATIONAL INSURANCE COMPANY LIMITED',
    'RAHEJA QBE GENERAL INSURANCE COMPANY LIMITED',
    'RELIANCE GENERAL INSURANCE CO.LTD.',
    'ROYAL SUNDARAM ALLIANCE INSURANCE CO.LTD.',
    'SAP Insurance Company',
    'SBI GENERAL INSURANCE COMPANY LIMITED',
    'SHRIRAM GENERAL INSURANCE COMPANY LIMITED',
    'TATA AIG GENERAL INSURANCE CO.LTD.',
    'THE NEW INDIA ASSURANCE CO. LTD.',
    'THE ORIENTAL INSURANCE CO. LTD.',
    'UNITED INDIA INSURANCE CO.LTD.',
    'UNIVERSAL SOMPO GENERAL INSURANCE CO.LTD.',
  ],
  ckyc: {
    ckycProvider: 'default',
    dependent: [dependencies.kycGstin],
    // example - dependent: [signature],
    search: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true,
          supporting: false,
          dependent:[dependencies.fatherName],
        },
        pan: {
          name: 'PAN Card',
          primary: true,
          supporting: true,
          dependent:[dependencies.dummyDropdown],
        },
        dependent:[dependencies.motherName]
    // example - dependent: [transactionNumber],

      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true,
          supporting: false,
          dependent:[],
        },
        gstin: {
          name: 'GSTIN',
          primary: false,
          supporting: true,
          dependent:[],
        },
        dependent:[dependencies.motherName]
        // example - dependent: [],
      },
      dependent:[]
    },
    upload: {
      individual: {
        aadhaar: {  
          name: 'Aadhaar Card',
          primary: true,
          supporting: false,
          frontAndBack: true,
          noInput:true,
          dependent:[dependencies.voterId,dependencies.dummyDropdown],
        },
        pan: {
          name: 'PAN Card',
          primary: true,
          supporting: true,
          noInput:true,
          dependent:[],
        },
        dependent:[]
        // example - dependent: [],
    
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true,
          supporting: false,
          dependent:[],
        },
        gstin: {
          name: 'GSTIN',
          primary: false,
          supporting: true,
          dependent:[],
        },
        dependent:[]
        // example - dependent: [],
    
      },
      dependent:[]
    },
  },
  inspectionRequired: true,
  inspectionSizeLimit: 0.48,
  inspectionTypes: {
    front: 'Front',
    left: 'Left',
    right: 'Right',
    rear: 'Rear',
    odometer: 'Odometer',
    chassisNumber: 'Chassis Number',
    report: 'Inspection Report',
    agent: "Agent with vehicle holding today's newspaper",
  },
};
