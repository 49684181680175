import { history } from 'chistory';
import { decode } from 'jsonwebtoken';
import { axios } from 'utility/api';

const ls = localStorage;

export const loginWithJWT = (user) => {
  return (dispatch) => {
    axios
      .post('/common/login', user)
      .then(({ data }) => {
        let user;
        try {
          user = decode(data.token);
        } catch (error) {
          return console.error('invalid token', error);
        }
        if (!user.active) {
          return console.error('Account inactive');
        }
        ls.setItem('AEGIS_USER', JSON.stringify(user));
        ls.setItem('DEALERS_LIST', JSON.stringify(data.dealers));
        dispatch({
          type: 'LOGIN_WITH_JWT',
          payload: { user, loggedInWith: 'jwt' },
        });

        const dealerPolicyRoute = user.insurance_active ? '/user/insurance' : '/user/certificate';
        window.location.href = dealerPolicyRoute;
      })
      .catch((err) => console.log(err));
  };
};

const logoutLocal = (dispatch: any) => {
  ls.removeItem('AEGIS_USER');
  ls.removeItem('DEALERS_LIST');
  dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
  history.push('/');
};
export const logoutWithJWT = (local = false) => {
  return (dispatch) => {
    if (local) {
      return logoutLocal(dispatch);
    }
    axios.post('/common/logout').then(() => {
      logoutLocal(dispatch);
    });
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};
