import React from 'react';
import { AlertCircle, Circle, FilePlus, FileText, List, Mail, Shield, ShieldOff } from 'react-feather';

const navigationConfig = [
  {
    id: "email",
    title: "Email",
    type: "item",
    icon: <Mail size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/email/:filter",
    filterBase: "/email/inbox",
    disabled: true,
    newTab: true,
    collapsed: true,
    parentOf: [
      "/pages/test"]
  }
];

const USER = JSON.parse(localStorage.getItem('AEGIS_USER') as any) as any;
if (USER) {
  const dealers = USER?.dealer_type === 'Dealer'
                  ? JSON.parse(localStorage.getItem('DEALERS_LIST') as any) as any
                  : [
                    {
                      name: USER.name,
                      dealer_sheet_id: USER.dealer_sheet_id,
                      insurance_sheet_id: USER.insurance_sheet_id,
                    }
                  ];
  // Check if insurance allowed
  if (USER?.insurance_active) {
    const childrenMasterInsurance = [] as any;
    const childrenCancellations = [] as any;
    // Adds list view for insurance create button & view insurance policies
    navigationConfig.push({
      id: 'insurance-section',
      title: 'Insurance',
      type: 'collapse',
      icon: <Shield size={20} />,
      collapsed: false,
      children: childrenMasterInsurance
    } as any);

    if (USER?.insurance_active === 2) {
      // Create policy button allowed if dealer active
      childrenMasterInsurance.push({
        id: "create-tw-insurance-policy",
        title: "Create Policy",
        type: "item",
        icon: <FilePlus size={20} className="ml-1"/>,
        navLink: "/user/insurance",
        disabled: false,
        newTab: false,
        collapsed: true
      } as any);
      childrenMasterInsurance.push({
        id: "failed-proposals",
        title: "Failed Proposals",
        type: "item",
        icon: <AlertCircle size={20} className="ml-1"/>,
        navLink: "/user/failed-proposals",
        disabled: false,
        newTab: false,
        collapsed: true
      } as any);
    }

    if (dealers.length === 1) {
      // View policies for dealers
      childrenMasterInsurance.push({
        id: "view-insurance-policies",
        title: "View Policies",
        type: "external-link",
        icon: <List size={20} className="ml-1"/>,
        newTab: true,
        navLink: `https://docs.google.com/spreadsheets/d/${(USER).insurance_sheet_id}/edit#gid=0`,
        collapsed: true
      } as any);
    } else {
      // View policies
      const viewInsuranceConfig = {
        id: "view-insurance-policies",
        title: "View Policies",
        type: "collapse",
        icon: <List size={20} className="ml-1"/>
      } as any;
      
      const insuranceDealers = dealers.filter(x => x?.insurance_sheet_id);
      const children: any = [];
      insuranceDealers.forEach((dealer, index) => {
        // View policies for each dealer
        children.push({
          id: dealer.insurance_sheet_id + index,
          title: dealer.name,
          type: "external-link",
          newTab: true,
          icon: <Circle size={12} className="ml-2"/>,
          navLink: `https://docs.google.com/spreadsheets/d/${dealer.insurance_sheet_id}/edit#gid=0`
        })
      });
  
      viewInsuranceConfig.children = children
  
      childrenMasterInsurance.push(viewInsuranceConfig);
    }

    childrenMasterInsurance.push({
      id: 'cancellation-section',
      title: 'Cancellations',
      type: 'collapse',
      icon: <ShieldOff size={20} />,
      collapsed: false,
      children: childrenCancellations
    } as any)
    
    childrenCancellations.push({
      id: 'cancel-policy',
      title: 'Cancel Policy',
      type: 'item',
      icon: (
        <span
          className="ml-1"
          style={{
            boxSizing: 'border-box',
            fontSize: '1.2rem',
            fontWeight: 'normal',
            margin: '-1rem 1.5rem -1rem 6px',
          }}
        >
          &nbsp;X
        </span>
      ),
      navLink: '/user/cancel-policy',
      disabled: false,
      newTab: false,
      collapsed: true,
    } as any);

     
    childrenCancellations.push({
      id: 'view-cancellations',
      title: 'View Cancellations',
      type: 'item',
      icon: <List size={20} className="ml-1"/>,
      navLink: '/user/cancellations',
      disabled: false,
      newTab: false,
      collapsed: true,
    } as any);
  }

  // Check if certficate allowed
  if (USER?.certificate_active) {
    const childrenMasterCertificate = [] as any;

    // Adds list view for certificate create button & view certificates
    navigationConfig.push({
      id: 'certificate-section',
      title: 'Certificates',
      type: 'collapse',
      icon: <FileText size={20} />,
      collapsed: false,
      children: childrenMasterCertificate
    } as any);

    if (USER?.certificate_active === 2) {
      // Create certficates button allowed if dealer certificate active
      childrenMasterCertificate.push({
        id: "create-certificate",
        title: "Create Certificate",
        type: "item",
        icon: <FilePlus size={20} className="ml-1"/>,
        navLink: "/user/certificate",
        disabled: false,
        newTab: false,
        collapsed: true
      } as any);
    }

    if (dealers.length === 1) {
      // View certificates
      childrenMasterCertificate.push({
        id: "view-certficates",
        title: "View Certificates",
        type: "external-link",
        icon: <List size={20} className="ml-1"/>,
        newTab: true,
        navLink: `https://docs.google.com/spreadsheets/d/${(USER).dealer_sheet_id}/edit#gid=0`,
        collapsed: true
      } as any);
    } else {
      // View certificate
      const viewInsuranceConfig = {
        id: "view-certficates",
        title: "View Certificates",
        type: "collapse",
        icon: <List size={20} className="ml-1"/>
      } as any;
      
      const insuranceDealers = dealers.filter(x => x?.dealer_sheet_id);
      const children: any = [];
      insuranceDealers.forEach((dealer, index) => {
        // View certificates for each dealer
        children.push({
          id: dealer.dealer_sheet_id + index,
          title: dealer.name,
          type: "external-link",
          newTab: true,
          icon: <Circle size={12} className="ml-2"/>,
          navLink: `https://docs.google.com/spreadsheets/d/${dealer.dealer_sheet_id}/edit#gid=0`
        })
      });
  
      viewInsuranceConfig.children = children
  
      childrenMasterCertificate.push(viewInsuranceConfig);
    }
  }

  // Reorder navigation config
  if (USER?.insurance_active !== 2 && USER?.certificate_active === 2) {
    navigationConfig.reverse();
  }

  // Check if add balance active
  if (USER?.add_balance && (USER?.insurance_active === 2 || USER?.certificate_active === 2)) {
    const childrenWallet = [] as Array<any>;

    childrenWallet.push({
      id: 'add-balance',
      title: 'Add Balance',
      type: 'item',
      icon: <span style={{ margin: '0px 25px 0px 20px' }}>₹</span>,
      navLink: '/user/add-balance',
      disabled: false,
      newTab: false,
      collapsed: true,
    });

    childrenWallet.push({
      id: 'payment-history',
      title: 'Top-Up History',
      type: 'item',
      icon: <List size={20} className="ml-1"/>,
      navLink: '/user/balance/history',
      disabled: false,
      newTab: false,
      collapsed: true,
    })

    navigationConfig.push({
      id: 'wallet',
      title: 'Wallet',
      type: 'collapse',
      icon: <img src="/wallet.png" height="16px" width="16px" style={{ margin: '0px 16px 0px 3px' }}/>,
      collapsed: false,
      children: childrenWallet
    } as any);
  }

}
export default navigationConfig;
