import { axios } from 'utility/api';

const getBalance = (dealerSheetId) => {
  return axios
    .get(`misc/balance/${dealerSheetId}`)
    .then(({ data }) => {
      if (data !== null && !data) {
        throw new Error('Unable to get balance.');
      }
      return data;
    })
    .catch((err) => {
      throw new Error('Unable to get balance.');
    });
};

export { getBalance };
