import { history } from "chistory"
import React from "react"
import * as Icon from "react-feather"
import { connect } from "react-redux"
import {
  // DropdownItem,
  NavItem,
  NavLink,
  UncontrolledTooltip
} from "reactstrap"
import {
  loadSuggestions,
  updateStarred
} from "redux/actions/navbar/Index"

interface NBProps {
  handleAppOverlay: (param: string) => void;
  updateStarred: any;
  sidebarVisibility: (param: any) => void;
  loadSuggestions: () => any;
  bookmarks: any;
};

class NavbarBookmarks extends React.PureComponent<NBProps> {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: []
  }

  updateBookmarks = false

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      noSuggestions: false,
      starred: null
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookmarks.starred.length !== this.state.starredItems.length && this.updateBookmarks === true) {
      this.setState({ starredItems: this.props.bookmarks.starred })
      this.updateBookmarks = false
    }
  }

  componentDidMount() {
    // let {
    //   bookmarks: { suggestions, starred },
    //   loadSuggestions
    // } = this.props
    // this.setState(
    //   {
    //     suggestions: suggestions,
    //     starredItems: starred
    //   },
    //   loadSuggestions()
    // )
  }

  renderBookmarks = () => {
    return <div className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards"
    >
      {this.state.starredItems.map((item: any, index) => {
        const IconTag = Icon[item.icon ? item.icon : "X"]
        return (<div key={index}>
          <NavItem className="nav-item d-none d-lg-block">
            <NavLink
              tag="span"
              id={item.target}
              className="nav-link cursor-pointer"
              onClick={() => history.push(item.link)}

            >
              <IconTag size={21} />
            </NavLink>
            <UncontrolledTooltip placement="bottom" target={item.target}>
              {item.title}
            </UncontrolledTooltip>
          </NavItem>
        </div>
        )
      })}
    </div>
  }



  render() {
    let {
      // bookmarks: { /* extraStarred, suggestions */ },
      sidebarVisibility,
      // updateStarred,
      // handleAppOverlay
    } = this.props

    // const renderExtraStarred =
    //   extraStarred.length > 0
    //     ? extraStarred.map(i => {
    //       const IconTag = Icon[i.icon ? i.icon : null]
    //       return (
    //         <DropdownItem key={i.id} href={i.link}>
    //           <IconTag size={15} />
    //           <span className="align-middle ml-1">{i.title}</span>
    //         </DropdownItem>
    //       )
    //     })
    //     : null

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          {this.renderBookmarks()}
          <NavItem
            className="nav-item d-none d-lg-block"
            onClick={this.handleBookmarksVisibility}
          >
          </NavItem>
        </ul>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    bookmarks: state.navbar
  }
}

export default connect(mapStateToProps, { loadSuggestions, updateStarred })(
  NavbarBookmarks
)
