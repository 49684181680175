import { initialValidation } from "views/user/CreateInsurance/Fields";

const initialState = {
        validation: initialValidation as any,
      };
      
export const modifyValidation = (state = initialState, action: { type: string; payload: any }): any => {
switch (action.type) {
        case 'MODIFY_VALIDATION':
        return { ...state, validation: { ...state.validation, ...action.payload }};
        default:
        return state;
}
};
      