// import { history } from "chistory"
import React from "react"
import * as Icon from "react-feather"
import { Link } from 'react-router-dom'
import {
  DropdownItem, DropdownMenu,
  DropdownToggle,
  // Media,
  UncontrolledDropdown
} from "reactstrap"
import CreditDisplay from 'views/user/CreditDisplay'

// const handleNavigation = (e, path) => {
//   e.preventDefault()
//   history.push(path)
// }

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem
        as={Link}
        href="/user/change-password"
      >
        <Icon.Key size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="/"
        onClick={e => {
          e.preventDefault()
          return props.logoutWithJWT();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

interface NUProps {
  handleAppOverlay: (p1: string) => void;
  userImg: string;
  userName: string;
  userType: string;
  logoutWithJWT: () => any;
};

class NavbarUser extends React.PureComponent<NUProps> {
  state = {
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [
      {
        id: 2,
        name:
          "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
        desc:
          "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
        price: "$1599.99",
        img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
        width: 100,
        imgClass: "mt-1 pl-50"
      },
      {
        id: 6,
        name:
          "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
        desc:
          "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
        price: "$4099.99",
        img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
        width: 70,
        imgClass: "mt-1 pl-50"
      }
    ],
    suggestions: []
  }

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult })
    // })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user w-100">
        <UncontrolledDropdown tag="li" className="nav-item mr-auto">
          <span className="nav-link">
            <CreditDisplay/>
          </span>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status text-capitalize">{this.props.userType}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
