import { combineReducers } from 'redux';
import auth from './auth/';
import customizer from './customizer/';
import navbar from './navbar/';
import validationReducers from './validation/';

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  validation:validationReducers
});

export default rootReducer;
