
const MODIFY_VALIDATION = 'MODIFY_VALIDATION';


interface ModifyValidationAction {
  type: typeof MODIFY_VALIDATION;
  payload: any; 
}


export const modifyValidationAction = (newValidationData: any): ModifyValidationAction => {
  return {
    type: MODIFY_VALIDATION,
    payload: newValidationData,
  };
};
