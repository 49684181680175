import classnames from "classnames"
import React from "react"

interface RadioProps {
  autoFocus?: boolean;
  className?: string;
  color: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  checked?: boolean;
  value?: string;
  size?: string;
  label: string;
  name: string;
  ref?: any;
  onFocus?: (p1: any) => void;
  onBlur?: (p1: any) => void;
  onClick?: (p1: any) => void;
  onChange?: (p1: any) => void;
};

class RadioVuexy extends React.Component<RadioProps> {
  render() {
    return (
      <div
        className={classnames(
          `vx-radio-con ${this.props.className} vx-radio-${this.props.color}`
        )}
      >
        <input
          type="radio"
          defaultChecked={this.props.defaultChecked}
          value={this.props.value}
          disabled={this.props.disabled}
          name={this.props.name}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
          ref={this.props.ref}
          checked={this.props.checked}
          autoFocus={this.props.autoFocus}
        />
        <span
          className={classnames("vx-radio", {
            "vx-radio-sm": this.props.size === "sm",
            "vx-radio-lg": this.props.size === "lg"
          })}
        >
          <span className="vx-radio--border" />
          <span className="vx-radio--circle" />
        </span>
        <span>{this.props.label}</span>
      </div>
    )
  }
}
export default RadioVuexy
