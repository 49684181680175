const initialState = {
  user: JSON.parse(localStorage.getItem('AEGIS_USER') as any),
};

export const login = (state = initialState, action: { type: string; payload: any; userRole: string }) => {
  switch (action.type) {
    case 'LOGIN_WITH_EMAIL': {
      return { ...state, values: action.payload };
    }
    case 'LOGIN_WITH_JWT': {
      return { ...state, ...action.payload };
    }
    case 'LOGOUT_WITH_JWT': {
      return {};
    }
    case 'CHANGE_ROLE': {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
