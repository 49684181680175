import axios from 'axios';
import { logoutWithJWT } from 'redux/actions/auth/loginActions';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.withCredentials = true;

// * adding interceptors
const interceptor = (store) => {
  axios.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (e) => {
      const response = e.response;
      if (response?.status === 401) {
        console.log('cleared user', response?.data);
        logoutWithJWT(true)(store.dispatch);
      }
      return Promise.reject(e);
    },
  );
};

export default axios;
export { axios, interceptor };
