import themeConfig from 'configs/themeConfig';
import FullLayout from 'layouts/FullpageLayout';
import VerticalLayout from 'layouts/VerticalLayout';
import React from 'react';
const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
};

type ILState = {
  activeLayout: string,
  width: number,
  lastLayout: null,
  direction: string,
};

type ILContext = {
  fullLayout: typeof FullLayout,
  state: ILState,
  VerticalLayout: any;
};

const ContextLayout = React.createContext<Partial<ILContext>>({});

class Layout extends React.Component {
  state = {
    activeLayout: themeConfig.layout,
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction,
  };

  componentDidMount = () => {
    this.setState({
      activeLayout: 'vertical',
    });
  };

  render() {
    const { children } = this.props;
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts.full,
          VerticalLayout: layouts.vertical
        }}
      >
        {children}
      </ContextLayout.Provider>
    );
  }
}

export { Layout, ContextLayout };
