import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { getBalance } from 'utility/getBalance';

const CreditBalance = (user) => {
  const [balance, setBalance] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    getBalance(user.dealer_sheet_id)
      .then((account: any) => {
        account.Balance = parseFloat(account['Combined Balance']);
        setBalance(account.Balance);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
  });

  return (
    <h4 style={{margin: 0}}>
      Balance:&nbsp;
      {typeof balance != 'number' && !error && (
        <span className="text-center">
          <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="primary" />
        </span>
      )}
      {typeof balance == 'number' && <span className={(balance > 0 ? "text-success" : "text-danger")}>{balance >= 0 ? "₹" + balance : "-₹" + Math.abs(balance) }</span>}
      {error && <p className="text-danger">Unable to get balance credits.</p>}
    </h4>
  );
};

const mapStateToProps = (state) => state.auth.login.user;

export default connect(mapStateToProps)(CreditBalance);
